import React from "react"
import { Link } from "gatsby"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Articles from "../components/Articles"
import Slider from "react-slick"
import "./index.scss"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      goldsmithImage: file(relativePath: { eq: "H_goldsmith_hero@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      groupImage: file(relativePath: { eq: "H_group_hero@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kotlerImage: file(relativePath: { eq: "H_kotler_hero@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      verneImage: file(relativePath: { eq: "VERNE_HS.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mg: file(relativePath: { eq: "H_golsmith_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pk: file(relativePath: { eq: "H_kotler_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rk: file(relativePath: { eq: "H_Ron_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rs: file(relativePath: { eq: "H_Robin_nid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jd: file(relativePath: { eq: "H_Jack_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hs: file(relativePath: { eq: "H_Simon_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vh: file(relativePath: { eq: "V_VH_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brands: file(relativePath: { eq: "H_logos@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const slidercSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",
    pauseOnHover: true,
  }

  const [openContact, SetOpenContact] = React.useState(false)

  return (
    <Layout
      formTrigger={openContact}
      cb={SetOpenContact}
      formOptions={{
        heading: "Contact Us",
        id: "8b55c90a-66fe-4e0e-bc93-0e6e2ef19015",
      }}
    >
      <SEO title="Home" />
      <section className="fold1">
        <div className="container">
          <div className="leftCol col fit">
            <h1>Ideas. Inspirations. Results.</h1>
            <p>
              The leading international symposium for the executive community,
              where global icons debate the most pressing issues of the day.
            </p>
            <button
              onClick={() => SetOpenContact(!openContact)}
              className="round pink"
            >
              Let’s Talk >{" "}
            </button>
          </div>
          <div className="rightCol col">
            <Img
              className="hero_img_set spot_shadow"
              fluid={data.goldsmithImage.childImageSharp.fluid}
              alt="goldsmith"
            />
            {/* <Img
              className="hero_img_set spot_shadow"
              fluid={data.groupImage.childImageSharp.fluid}
              alt="goldsmith"
            /> */}
            {/* <Img
              className="hero_img_set spot_shadow"
              fluid={data.kotlerImage.childImageSharp.fluid}
              alt="goldsmith"
            /> */}
            <Img
              className="hero_img_set spot_shadow"
              fluid={data.verneImage.childImageSharp.fluid}
              alt="verne"
            />
          </div>
        </div>
      </section>
      <section className="fold2">
        <div className="container">
          <h2 className="fit-text">
            Committed to providing an extraordinary perspective for educating
            business leaders. Our business workshops /programs present a global
            perspective in a variety of formats - with each program designed to
            leave a lasting impact on you and your organization.
            <br />
            <br />
            You will hear from some of the world-renowned speakers and business
            thinkers that continue to advance management science and develop
            cutting-edge tools and frameworks to help businesses transform their
            ideas into products and bring those products to market.
          </h2>
        </div>
        <div className="dottedWhite"></div>
      </section>
      <section className="wwa">
        <div className="container">
          <div className="meta">
            <h1>What we do</h1>
            <p>
              We bring together the best faculty, their research and business
              leadersto help you to address your business challenges and
              dramatically improve your business performance.
            </p>
          </div>
          <div className="heroRowItems">
            <div className="heroRowItem">
              <h2>Experts</h2>
              <p>
                Learn from greatest thought leaders, renowned visionaries,
                creative problem solvers, bestselling authors, and business
                authorities.
              </p>
              <Link to="/experts" className="round pink">
                Learn More {">"}{" "}
              </Link>
            </div>
            <div className="heroRowItem">
              <h2>Platform</h2>
              <p>
                Empower your business leaders to thrive, by becoming ‘adaptable’
                with a robust ‘Learn. Do. Review.’ result oriented coaching
                system.
              </p>
              <Link to="/platform" className="round pink">
                Learn More {">"}{" "}
              </Link>
            </div>
            <div className="heroRowItem">
              <h2>Programs</h2>
              <p>
                Access the world’s greatest business minds via exclusive events,
                executive masterclasses and private learning sessions.
              </p>
              <Link to="/workshops" className="round pink">
                Learn More {">"}{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="btn-sec">
        <div className="container">
          <div className="col colLeft">
            <div className="meta">
              <h1>By the numbers</h1>
              <p>
                Helping business leaders thrive through change for over 15
                years.
              </p>
            </div>
          </div>
          <div className="col colRight">
            <div className="btn-sec-items">
              <div className="btn-sec-item">
                <h3>40</h3>
                <h4>Events</h4>
                <hr />
                <p>Experiences that inspire business leaders</p>
              </div>
              <div className="btn-sec-item">
                <h3>10000+</h3>
                <h4>Attendees</h4>
                <hr />
                <p>Actionable insights from boardroom to factory floor</p>
              </div>
              <div className="btn-sec-item">
                <h3>15</h3>
                <h4>Experts</h4>
                <hr />
                <p>Intensive, action-oriented change makers</p>
              </div>
              <div className="btn-sec-item">
                <h3>3000+</h3>
                <h4>Companies</h4>
                <hr />
                <p>Motivate teams for peak performance.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="quoteSlider">
        <Slider {...slidercSettings}>
          <div className="sliderItem ">
            <div className="container">
              <blockquote>
                {" "}
                “Great speakers on board, truly powering business ideas and
                stimulating <br /> knowledge.”{" "}
              </blockquote>
              <span>
                <b>Sanjeev Mohanty</b> <br />
                Managing Director & SVP <br />
                South Asia, Levi Strauss & Co.
              </span>
            </div>
          </div>
          <div className="sliderItem ">
            <div className="container">
              <blockquote>
                {" "}
                “Knowledge Capital’s platform brings together world class
                trainers”{" "}
              </blockquote>
              <span>
                <b>David R Stanley</b> <br />
                Managing Director <br />
                Healux International Pvt. Ltd.
              </span>
            </div>
          </div>
          <div className="sliderItem ">
            <div className="container">
              <blockquote>
                {" "}
                “Great job in picking the most relevant subject matter and the
                speakers”{" "}
              </blockquote>
              <span>
                <b>Paresh Desai</b> <br />
                Managing Director, <br />
                AUDITime Information Systems (I) Ltd
              </span>
            </div>
          </div>
          <div className="sliderItem ">
            <div className="container">
              <blockquote>
                {" "}
                “Good, Smart elegant organization capabilities.”{" "}
              </blockquote>
              <span>
                <b>Deepak Sethi</b> <br />
                CEO <br />
                SolutionsQED
              </span>
            </div>
          </div>
        </Slider>
      </section>
      <section className="heroExperts">
        <div className="container">
          <div className="meta">
            <h1>Our experts</h1>
            <p>
              Learn from greatest thought leaders, renowned visionaries,
              creative problem solvers, bestselling authors, and business
              authorities.
            </p>
          </div>
          <div className="expertsGrid">
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.mg.childImageSharp.fluid}
                alt="Marshal Goldsmith"
              />
              <h2>
                Marshal <br /> Goldsmith
              </h2>
              <p>Leadership Thinker, Exec Coach, NYT Bestseller</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.vh.childImageSharp.fluid}
                alt="Verne Harnish"
              />
              <h2>
                Verne <br /> Harnish
              </h2>
              <p>
                World renowned Speaker, Author, and Founder of the
                Entrepreneurs’ Organization (EO)
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.rk.childImageSharp.fluid}
                alt="Ron Kaufman"
              />
              <h2>
                Ron <br /> Kaufman
              </h2>
              <p>
                Award-winning Customer Exp Consultant, Leadership & Motivational
                Keynote Speaker
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.hs.childImageSharp.fluid}
                alt="Hermman Simon"
              />
              <h2>
                Hermman <br /> Simon
              </h2>
              <p>
                Author & Chairman of Simon-Kucher & Partners, Strategy &
                Marketing Consultants
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.rs.childImageSharp.fluid}
                alt="Robin Sharma"
              />
              <h2>
                Robin <br /> Sharma
              </h2>
              <p>Writer and Leadership Speaker</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.jd.childImageSharp.fluid}
                alt="Jack Daly"
              />
              <h2>
                Jack <br /> Daly
              </h2>
              <p>Sales Management Expert</p>
            </div>
          </div>
          <Link className="round pink" to="/experts">
            View All {">"}
          </Link>
        </div>
      </section>
      {/* <section className="heroVideo">
        <div className="container">
          <div className="speakerQuote">
            <blockquote>
              Overcome The Growth Paradox Keeping you from 10x-ing your company.
            </blockquote>
            <h1>Verne Harnish</h1>
            <span>
              Scaleup Expert. World-Renowned Speaker. Bestselling Author
            </span>
          </div>
          <button className="videoPlaybutton"></button>
        </div>
      </section> */}
      <section className="brands">
        <div className="container">
          <div className="meta">
            <h1>You’ll be in great company</h1>
            <p>
              From brick & mortar to e-commerce, from startups to enterprise We
              partner with humble, trusting leaders that think strategically and
              who have a zeal to challenge the status quo.
            </p>
          </div>
          <Img alt="brands" fluid={data.brands.childImageSharp.fluid} />
        </div>
      </section>
      {/* <Articles /> */}
    </Layout>
  )
}

export default IndexPage
